import React from 'react';
import {request, request_file} from './request.js';
import Principal from './Principal.js';
import PieDePagina2 from './Footer2.js';
import Solicitud from './Solicitud.js';
import Extraordinarios from './Extraordinarios.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../fonts/css/open-iconic.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import logo from '../images/logo1.png';
import cero from '../images/CeroTolerancia.jpg';
import './style.css';
import './animate.min.css';
import Swal from 'sweetalert2';
import instruccion1 from './../images/instruccion1.jpg';
import instruccion2 from './../images/instruccion2.jpg';
import instruccion3 from './../images/instruccion3.jpg';


class Descargas extends React.Component{

	constructor(props){
		super(props);

		this.state={
			usuario :
			{
				id : '',
				num_control : '',
				semestre : '',
				status : '',
				grupo : '',
				turno : '',
				especialidad : '',
				apellido_paterno : '',
				apellido_materno : '',
				nombre : '',
				curp : '',			
				referencia : '',
				cantidad : '',
				fecha_de_inscripcion : '',
				promedio :'',
				check1 : 0,
				check2 : 0,
				check3 : 0,
				check4 : 0,
				check5 : 0,
				check6 : 0,
				check7 : 0,
				check10 : 0,
				check11 : 0,
				check12 : 0,
				check_ref : 0,
				observaciones : '',
				constancia : 0,
				pagoextra : 0
			},
			correo :
			{
				correoi : null,
				password : null,
			},
			barra : 0,
			conectado : true,
			redireccion : 0,
			progreso : 0,
			generando : 0
		}
		this.descargar = this.descargar.bind(this);
		this.revisarCheck = this.revisarCheck.bind(this);
	}

	componentDidMount()
	{
		var { usuario, progreso, barra } = this.state;
		let temp = this;
		request.get('/buscarsp/'+this.props.id)
		.then(function(response)
		{
			if(response.status === 200)
			{
				usuario = response.data;
				barra = parseInt(usuario.check1,10) +
						parseInt(usuario.check2,10) +
						parseInt(usuario.check3,10) +
						parseInt(usuario.check4,10) +
						
						parseInt(usuario.check7,10);
				progreso=100/5*barra;
				usuario.check10=parseInt(usuario.check10,10);
				if( (parseInt(usuario.check1,10) + parseInt(usuario.check3,10) + parseInt(usuario.check4,10) +  parseInt(usuario.check7,10) ) === 4 )
				{
					usuario.check_ref=1;
				}
				temp.setState({ usuario : usuario, progreso : progreso, barra : barra });
			}
		})
		.catch(error => { });

		// Swal.fire({
		// 	html: '<b><b> <br> No olvides subir tu comprobante despues de realizar tu pago.<br><br> </b></b><br>',
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok'
		// });

		// Swal.fire({
		// 	html: '<b><br>Puedes consultar tu Historial Académico Actualizado en:<br><br>' + 
		// 		  '<b><a href="http://siiesmexico.com.mx"> siiesmexico.com.mx </a> </b><br><br>',
		// 	focusConfirm: false,
		// 	confirmButtonText: 'Ok'
		// });

	}

	desconectar=(e)=>
	{
		e.preventDefault();
		var {conectado} = this.state;
		conectado=false;
		this.setState({ conectado : conectado });
	}

	descargar(event,numarchivo)
	{
		var archivo='';
		switch(numarchivo)
		{
			case  1: archivo='Netiqueta'; break;
			case  2: /*Referencia de pago*/ break; 
			case  3: /*Solicitud de inscripcion*/ break;
			case  4: archivo='Normas_de_convivencia'; break;
			case  5: /*Actualizacion de datos*/ break;
			case  6: archivo='Carta_Compromiso'; break;
			case  7: /*Comprobante de pago*/ break;
			case  8: /*Constancia*/ break;
			case  9: /*Solicitud Tipo A*/ break;
			case 10: archivo='Consentimiento'; break;
			case 11: archivo='Aviso_de_privacidad'; break;
			default:
		}
		request_file()
		.post(`/descargar?archivo=${archivo}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL,"archivo.pdf");
			this.revisarCheck(numarchivo);
		})
		.catch(error => {
			console.log(error);
		});			
	}

	decargarReferencia=()=>
	{
		let { usuario } = this.state;

		// if(parseInt(usuario.referencia,10) == 9999)
		// {
		// 	Swal.fire({
		// 			toast: true,
		// 			icon: 'success',
		// 			title: 'Tu pago ya fue acreditado',
		// 			showConfirmButton: false,
		// 			timer: 3000
		// 	});
		// }		
		// else
		// {


			request_file()
			//.post('/referenciaform') // Para descargar formato de inscripción en blanco
			.post(`/referencia?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
				this.revisarCheck(2);
			})
			.catch(error => {
				console.log(error);
			});

			
		// }

		// Swal.fire({
		// 	toast: true,
		// 	icon: 'warning',
		// 	title: 'La referencia de pago no se encuentra disponible, espera el siguiente periodo',
		// 	showConfirmButton: false,
		// 	timer: 3000
		// });
	}

	sinDerechoaInscripcion=()=>
	{

		Swal.fire({
			toast: true,
			icon: 'warning',
			title: 'Aun no puedes iniciar tu proceso de inscripción, revisa el motivo en la Oficina de Control Escolar',
			showConfirmButton: false,
			timer: 5000
		});
	}

	decargarReferenciaCredencial=()=>
	{
		let { usuario } = this.state;

		request_file()
		.post(`/referenciaCredencial?num_control=${usuario.num_control}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			console.log(error);
		});
	}

	descargarHistorial=()=>
	{
		let { usuario } = this.state;
		// if(parseInt(usuario.semestre,10) === 3)
		// {
		// 	Swal.fire({
		// 		toast: true,
		// 		icon: 'warning',
		// 		title: 'Recuerda que si eres de segundo semestre debes acudir al plantel por tu boleta.',
		// 		showConfirmButton: false,
		// 		timer: 3000
		// 	});
		// }
		// else
		// {
			request_file()
			.post(`/descargarHistorial?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(error => {
				console.log(error);
				Swal.fire({
					toast: true,
					icon: 'warning',
					title: 'Tu historial aun no se encuentra disponible',
					showConfirmButton: false,
					timer: 3000
				});

			});
		// }
	}

	decargarBoleta=()=>
	{
		let { usuario, generando } = this.state;
		let temp = this;

		
		// if(parseInt(usuario.semestre,10) === 2)
		// {
			// Swal.fire({
			// 	html: '<b><br> Las boletas de <b>SEGUNDO SEMESTRE</b> se entregaran <b>en la escuela</b> '+
			// 	' de acuerdo al calendario.<br><br>'+
			// 	'Revisa las fechas en las redes sociales, y <b>no olvides llevar '+
			// 	'los documentos que se piden.</b><br><br> </b>',
			// 	focusConfirm: false,
			// 	confirmButtonText: 'Ok'
			// });
		// }
		// else
		// {
			request_file()
			.post(`/boleta?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
				generando = 0;
				temp.setState({  generando : generando });
			})
			.catch(error => {
				console.log(error);
			});

			generando = 1;
			this.setState({ generando : generando });
		//}
	}


	decargarConstancia=()=>
	{
		let { usuario } = this.state;
		// if(parseInt(usuario.promedio,10) === 5)
		// {
		// 	Swal.fire({
				
		// 		icon: 'warning',
		// 		title: 'No es posible generar tu contancia de estudios.',
		// 		html:  	'<br><b>Acude a la oficina de servicios escolares<br>'+
		// 				'para revisar el estatus de tus calificaciones.</b><br><br>',
		// 		showConfirmButton: true,
			
		// 		focusConfirm: true,
		// 		confirmButtonText: 'Ok'
		// 	});
		// }
		// else
		// {
			request_file()
			.post(`/constancia?num_control=${usuario.num_control}`)
			.then(response => {
				const file = new Blob([response.data], {type: 'application/pdf'});
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(error => {
				Swal.fire({
					
					icon: 'warning',
					text: 'No es posible generar tu contancia de estudios, acude a la oficina de control escolar.',
					showConfirmButton: true,
					focusConfirm: true,
					confirmButtonText: 'Ok'
				});
			});
		//}
	}

	decargarReporteTutores=()=>
	{
		var elgrupo="4AVL";

		request_file()
		.post(`/ReporteTutores?grupo=${elgrupo}`)
		.then(response => {
			const file = new Blob([response.data], {type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		})
		.catch(error => {
			Swal.fire({
				icon: 'warning',
				text: 'No es posible generar el reporte.',
				showConfirmButton: true,
				focusConfirm: true,
				confirmButtonText: 'Ok'
			});
		});
	}

	consultarCorreo=()=>
	{
		let { usuario } = this.state;
		var { correo } = this.state;
		let temp = this;

		request.get('/CorreoInstitucional/'+usuario.num_control) 
		.then(function(response)
		{
			if(response.status === 200)
			{
				correo = response.data;
				if(correo.correoi === null)
				{
					Swal.fire({
						toast: true,
						icon: 'warning',
						title: 'Aun no se genera tu correo institucional',
						showConfirmButton: false,
						timer: 3000
					});
				}
				else
				{
					Swal.fire({
						title: '<strong>Activa tu Correo Institucional</strong>',
						html:   `<br>`+
								`Entra a <br>` +
								// <b><a href="https://accounts.google.com/ServiceLogin?service=mail&passive=true&Email=${correo.correoi}&continue=https://mail.google.com/mail/u/${correo.correoi}/" target="_blank"> https://mail.google.com </a></b><br><br>
								`<b><a href="https://mail.google.com" target="_blank"> https://mail.google.com </a></b><br><br>` +
								`y activa tu cuenta de correo <b>`+
								`<br>${correo.correoi}</b><br><br>` +
								`utilizando la contraseña<br>`+ 
								`<b>${correo.password}</b>` +
								`<br><br>`,
						focusConfirm: false,
						footer: 'Si tienes algun problema con tu correo escribenos a: correos.soporte@cbtis041.edu.mx<br>'+
								'agregando tu nombre completo y grupo.',
					});
				}

				temp.setState({  correo : correo });
			}
		})
		.catch(error => { });
	}

	llenarSolicitud=()=>
	{
		var { redireccion, usuario } = this.state;

		//if(parseInt(usuario.check3,10)===1)
		//{

			if(usuario.status==='I')
			{
				Swal.fire({
					toast: true,
					icon: 'warning',
					title: 'No puedes llenar la solicitud de inscripción hasta regularizarte con tus materias',
					showConfirmButton: false,
					timer: 5000
				});
			}
			else
			{
				redireccion	= 3;
				this.setState({ redireccion : redireccion });
			}

		// }
		// else
		// {
		// 	Swal.fire('El periodo de inscripción y llenado de solicitud ha concluido.');
		// }
	}

	solicitarMateriasReprobadas=()=>
	{
		var {redireccion} = this.state;
		redireccion	= 9;
		this.setState({ redireccion : redireccion });
	}

	agregarFotografia=async()=>
	{
		let { usuario } = this.state;
		const { value: file } = await Swal.fire({
										title: 'Selecciona una imagen',
										input: 'file',
										inputAttributes: {
											'accept': 'image/*',
											'aria-label': 'Agrega tu foto'
										}
									});

		if (file) 
		{
			if( (file.type === 'image/png') || (file.type === 'image/jpg') || (file.type === 'image/jpeg') || (file.type === 'image/gif') )
			{
//				console.log(URL.createObjectURL(file));



				const imageData = new FormData();
				imageData.append('num_control',usuario.num_control);
				imageData.append('archivo',file);

				request.post('/CargarImagen',imageData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								toast: true,
								position: 'center-end',
								title: 'Imagen enviada',
								showConfirmButton: false,
								timer: 2500
							});
							//temp.setState({ subiendo : subiendo });
						}
						else
						{
							Swal.fire({
								toast: true,
								position: 'center-end',
								title: 'Hay un problema con la imagen',
								showConfirmButton: false,
								timer: 2500
							});
						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						toast: true,
						position: 'top-end',
						title: 'No se guardo la información.',
						showConfirmButton: false,
						timer: 1500
					});
				});
			}
			else
			{
				Swal.fire({
					toast: true,
					title: 'El archivo no es valido'
				});
			}
		}
	}

	subirComprobante = async ()=>
	{
		var { usuario } = this.state;
		let temp = this;

		try {

			const { value: archivo } = await Swal.fire({
												title: 'Adjuntar comprobante de pago',
												input: 'file',
												inputAttributes:
												{
													'accept': 'image/jpeg',
													'aria-label': 'Upload your profile picture'
												}
											})
			if (archivo) 
			{
				const fileData = new FormData();
				fileData.append('num_control',usuario.num_control);
				fileData.append('archivo',archivo);

				request.post('/CargarComprobante',fileData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								position: 'center-end',
								icon: 'success',
								title: 'Documento enviado',
								showConfirmButton: false,
								timer: 2500
							});
							usuario.check7 = 1;
							temp.setState({  usuario : usuario });
						}
						else
						{
							Swal.fire({
								position: 'center-end',
								icon: 'error',
								title: 'El archivo no es válido',
								showConfirmButton: false,
								timer: 2500
							});
						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No pudo enviarse el archivo',
						showConfirmButton: false,
						timer: 1500
					});
				});

			}
			else
			{
				Swal.fire('No se selecciono ningun archivo');
			}
		} catch (e) {
			console.log('error:', e);
			return false;
		}

	}

	subirConstanciaNSS = async ()=>
	{
		var { usuario } = this.state;
		let temp = this;

		try {

			const { value: archivo } = await Swal.fire({
												title: 'Adjuntar Constancia de NSS',
												input: 'file',
												inputAttributes:
												{
													'accept': 'application/pdf',
													'aria-label': 'Carga tu documento'
												}
											})
			if (archivo) 
			{
				const fileData = new FormData();
				fileData.append('num_control',usuario.num_control);
				fileData.append('archivo',archivo);

				request.post('/CargarConstanciaNSS',fileData)
				.then(function(response)
				{
					if(response.status === 200)
					{
						if(response.data)
						{
							Swal.fire({
								position: 'center-end',
								icon: 'success',
								title: 'Documento enviado',
								showConfirmButton: false,
								timer: 2500
							});
							usuario.check7 = 1;
							temp.setState({  usuario : usuario });
						}
						else
						{
							Swal.fire({
								position: 'center-end',
								icon: 'error',
								title: 'El archivo no es válido',
								showConfirmButton: false,
								timer: 2500
							});
						}
					}
				})
				.catch(error =>
				{
					Swal.fire({
						position: 'top-end',
						icon: 'error',
						title: 'No pudo enviarse el archivo',
						showConfirmButton: false,
						timer: 1500
					});
				});

			}
			else
			{
				Swal.fire('No se selecciono ningun archivo');
			}
		} catch (e) {
			console.log('error:', e);
			return false;
		}

	}

	estadistica=()=>
	{
		request.get('/Reporte')
		.then(function(response)
		{
			if(response.status === 200)
			{
				Swal.fire({
					title: '<strong>Reporte de progreso</strong>',
					html: `<b><b>Listas de cotejo:</b> &nbsp; ${response.data.listadecotejo} / 1000</b> <br>` +
						  `<b><b>Referencias:</b> &nbsp; ${response.data.referencias} / 1000</b> <br>` +
						  `<b><b>Solicitudes:</b> &nbsp; ${response.data.solicitudes} / 1000</b> <br>` +
						  `<b><b>Reglamento:</b> &nbsp; ${response.data.reglamento} / 1000</b> <br>` +
						  `<b><b>Calendario:</b> &nbsp; ${response.data.calendario} / 1000</b> <br>` +
						  `<b><b>Actualización de datos:</b> &nbsp; ${response.data.actualizaciondedatos} / 1000</b> <br>` +
						  `<b><b>Aviso de privacidad:</b> &nbsp; ${response.data.avisodeprivacidad} / 1000</b> <br>`,
				});
			}
		})
		.catch(error => { });
	}

	subirFotografia = ()=>
	{
		
		var { usuario } = this.state;
		let temp = this;


		if(usuario.check10 === 4)
		{
			Swal.fire({
				width: '600px',
				html: `<b>Motivo de rechazo de tu fotografia:</b><br><br>`+
						`<i>${usuario.observaciones}</i>`+
						`<br><br>`,
				confirmButtonText: 'Enterado'
			}).then((result) => {
				Swal.fire({
					width: '600px',
					html: ' <img src="'+ instruccion1 +'" width="500" height="600">',
					footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
					confirmButtonText: 'Siguiente...'
				}).then((result) => {
					if(result.value)
					{
						Swal.fire({
							width: '600px',
							html: ' <img src="'+ instruccion2 +'" width="500" height="600">',
							footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
							confirmButtonText: 'Siguiente...'
						}).then((result) => {
							if(result.value)
							{
								Swal.fire({
									width: '600px',
									html: ' <img src="'+ instruccion3 +'" width="500" height="600">',
									footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
									confirmButtonText: 'Entendido'
								}).then((result) => {
									if(result.value)
									{
										this.pedirfoto();
									}
								})
							}
						})
					}
				})
			});
		}
		else
		{
			Swal.fire({
				width: '600px',
				html: ' <img src="'+ instruccion1 +'" width="500" height="600">',
				footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
				confirmButtonText: 'Siguiente...'
			}).then((result) => {
				if(result.value)
				{
					Swal.fire({
						width: '600px',
						html: ' <img src="'+ instruccion2 +'" width="500" height="600">',
						footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
						confirmButtonText: 'Siguiente...'
					}).then((result) => {
						if(result.value)
						{
							Swal.fire({
								width: '600px',
								html: ' <img src="'+ instruccion3 +'" width="500" height="600">',
								footer: 'Si no puedes ver la imagen completa gira tu dispositivo',
								confirmButtonText: 'Entendido'
							}).then((result) => {
								if(result.value)
								{
									this.pedirfoto();
								}
							})
						}
					})
				}
			});
		}
	}

	async pedirfoto()
	{
		var { usuario } = this.state;
		let temp = this;

		try {

			
			const { value: archivo } = await Swal.fire({
				title: 'Adjuntar Fotografia',
				input: 'file',
				inputAttributes:
				{
					'accept': 'image/*',
					'aria-label': 'Cargar fotografia'
				}
			})

				if (archivo) 
				{
					const fileData = new FormData();
					fileData.append('num_control',usuario.num_control);
					fileData.append('archivo',archivo);

					request.post('/CargarFotografia',fileData)
					.then(function(response)
					{
						if(response.status === 200)
						{
							if(response.data)
							{
								Swal.fire({
									position: 'center-end',
									icon: 'success',
									title: 'Documento enviado',
									showConfirmButton: false,
									timer: 2500
								});
								usuario.check10 = 2;
								temp.setState({  usuario : usuario });
							}
							else
							{
								Swal.fire({
									position: 'center-end',
									icon: 'error',
									title: 'El archivo no es válido',
									showConfirmButton: false,
									timer: 2500
								});
							}
						}
					})
					.catch(error =>
					{
						Swal.fire({
							position: 'top-end',
							icon: 'error',
							title: 'No pudo enviarse el archivo',
							showConfirmButton: false,
							timer: 1500
						});
					});
				}
				else
				{
					console.log(archivo);
					Swal.fire('No se selecciono ningun archivo');
				}

			

		} catch (e) {
			console.log('error:', e);
			return false;
		}
	}

	solicitarSeguroSocial=()=>
	{
		window.open('https://serviciosdigitales.imss.gob.mx/', "_blank");
		this.revisarCheck(12);
	}

	revisarCheck(numero)
	{
		var { usuario, progreso, barra } = this.state;
		let temp = this;
		var mod=true;

		usuario.check1 = parseInt(usuario.check1,10);
		usuario.check2 = parseInt(usuario.check2,10);
		usuario.check3 = parseInt(usuario.check3,10);
		usuario.check4 = parseInt(usuario.check4,10);
		usuario.check5 = parseInt(usuario.check5,10);
		usuario.check6 = parseInt(usuario.check6,10);
		usuario.check7 = parseInt(usuario.check7,10);
		usuario.check10 = parseInt(usuario.check10,10);
		usuario.check11 = parseInt(usuario.check11,10);

		switch(numero)
		{
			case 1: if(usuario.check1 === 0) usuario.check1 = 1;  break;
			case 2: if(usuario.check2 === 0) usuario.check2 = 1;  break;
			case 3: 						 usuario.check3 = 1;  break;
			case 4: if(usuario.check4 === 0) usuario.check4 = 1;  break;
			
			case 6: if(usuario.check6 === 0) usuario.check6 = 1;  break;
			case 7: if(usuario.check7 === 0) usuario.check7 = 1;  break;
			case 10: if(usuario.check10 === 0) usuario.check10 = 1;  break;
			case 11: if(usuario.check11 === 0) usuario.check11 = 1;  break;
			case 12: if(usuario.check12 === 0) usuario.check12 = 1;  break;
			default: mod=false;
		}
		if( usuario.check1 === 1 && usuario.check3 === 1 && usuario.check4 === 1)
		{
			usuario.check_ref=1; mod=true;
		}
		barra = usuario.check1 + usuario.check2 + usuario.check3 + usuario.check4 + usuario.check7;
		progreso=100/5*barra;
		if(mod===true)
		{
			request.post('/ActualizarRegistro',usuario)
			.then(function(response)
			{
				if(response.status === 200)
				{
					if(response.data)
					{
						usuario=response.data;
						temp.setState({usuario : usuario, progreso : progreso, barra : barra});
					}
				}
			})
			.catch(error =>
			{
				
			});
		}
		this.setState({ usuario : usuario, progreso : progreso, barra : barra });
	}

	render(){

		let { usuario, conectado, redireccion, progreso, barra, generando } = this.state;

		if(!conectado) return <Principal />;
		if(redireccion === 3) return <Solicitud id={usuario.id} check3={usuario.check3} />;
		if(redireccion === 9) return <Extraordinarios id={usuario.id} pagoextra={usuario.pagoextra} />;

		return (
			<div className="principal">

				{/* MENU SUPERIOR */}

				<nav className="menu pb-2 pt-1 navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
					<img src={logo} style={{height: '45px'}} className="ml-2 navbar-brand img-fluid img-thumbnail" alt="logo1" />
					&nbsp;
					<span
						className="nombre oi navbar-brand bl titulonombre h-100 align-middle" data-glyph="person" aria-hidden="true">
						<i> &nbsp;{(usuario.nombre)} 
						{ /* {usuario.apellido_paterno} {usuario.apellido_materno} */ }
						</i>
					</span>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" style={{ position: 'absolute', right: '40px' }} id="navbarNav">
						<div className="navbar-nav ">
							<button className="nav-item nav-link btn salir" onClick={this.desconectar}>
								<span className="oi" data-glyph="power-standby" aria-hidden="true"></span> SALIR
							</button>
							{
								(parseInt(usuario.num_control,10)===1500)?
									<button className="nav-item nav-link btn salir" onClick={this.estadistica}>
										<span className="oi" data-glyph="grid-three-up" aria-hidden="true"></span>
									</button>
								:""
							}
							{
								(parseInt(usuario.num_control,10)===1501)?
									<button className="nav-item nav-link btn salir" onClick={this.estadistica}>
										<span className="oi" data-glyph="grid-three-up" aria-hidden="true"></span>
									</button>
								:""
							}
						</div>
					</div>
				</nav>

				<div id="inicio" className="inicio container-fluid">
					<div className="row espacio2"> </div>
					<div className="row pb-2 h-100 justify-content-around">
						<div className="col-12 col-sm-10 align-self-center pb-4">
							<p className="kau bl lg"> Bienvenido&nbsp;al "Sistema&nbsp;de&nbsp;Registro Escolar" del&nbsp; C.B.T.i.s.&nbsp;#41</p>
						</div>
						<div className="col-12 col-sm-10">
					{/*		<div className="lat bl izq sm">
								<b> INSTRUCCIONES:</b> <br /> <br />
								&emsp;-&emsp;A CONTINUACIÓN SE MUESTRAN LAS LIGAS DE DESCARGA DE LOS DOCUMENTOS QUE NECESITAS
								PARA PODER REALIZAR TU PROCESO DE REINSCRIPCIÓN.<br /> <br />
								&emsp;-&emsp;PARA PODER DESCARGAR TU REFERENCIA DE PAGO, PRIMERO DEBERAS REALIZAR LAS ACTIVIDADES QUE CORRESPONDEN
								A CADA UNA DE LAS OPCIONES. <br />  <br />
								<i>Revisa todas las ligas y descarga los documentos: </i>
								<div className="progress">
									<span className="progress-value negra cen"> <b><i> {barra} / 5 </i></b> </span>
									<div className="progress-bar bg-warning" role="progressbar" style={{width: (progreso)+'%'}} aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>*/}
							<div className="lat bl izq sm">
								<b> <span className="am">INSTRUCCIONES:</span> </b> <br /> <br />
								&emsp;<span className="am"><b>1)</b></span>&emsp;Descarga tu referencia bancaria y realiza tu pago.<br />
								{/*&emsp;<span className="am"><b>2)</b></span>&emsp;Genera tu constancia de número de seguro social y súbela a la plataforma. <br />*/}
								{/*&emsp;<span className="am"><b>2)</b></span>&emsp;Espera al día siguiente a que el banco acredite tu pago (No es necesario que envies el comprobante).<br />*/}
								&emsp;<span className="am"><b>2)</b></span>&emsp;Llena tu solicitud de inscripción.<br />
								&emsp;<span className="am"><b>3)</b></span>&emsp;Descarga las normas de convivencia, leelas con tus padres, imprime la ultima hoja y fírmenla. <br />
								&emsp;<span className="am"><b>4)</b></span>&emsp;Llena el consentimiento para autorización de pertenencias personales. <br />
								{/*&emsp;<span className="am"><b>6)</b></span>&emsp;Descarga el Aviso de privacidad, tambien debe firmarlo tu padre o tutor. <br />*/}
								&emsp;<span className="am"><b>5)</b></span>&emsp;Revisa el calendario de inscripciones para que sepas cuando debes entregar tus documentos. <br />
								{/*&emsp;<span className="am"><b>7)</b></span>&emsp;Acude a la escuela el día que te corresponde, además de tus documentos lleva una polo o camisa blanca para que te tomes la fotografía para tu credencial. <br />*/}
								<br /><br />
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-12 col-lg-11 col-xl-10">
							<div className="container-fluid">
								<div className="row justify-content-center">

					{/* BOLETA */}


									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> </span>
													Boleta de calificaciones
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui podras descargar tu boleta con tus calificaciones parciales
												</p>
											</div>
											<div className="card-footer text-muted">
											{
												generando===1 ?
													<button className="btn btn-warning">
														<b>Generando boleta...</b>
													</button>
												:
													<button className="btn btn-primary" onClick={this.decargarBoleta}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
											}
											</div>
										</div>
									</div>


				{/* REFERENCIA DE PAGO */}

									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													{
														parseInt(usuario.check2,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
														: <span className="oi" data-glyph="spreadsheet" aria-hidden="true"> </span>
													}
													Referencia Bancaria
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Con esta hoja podrás acudir a tu banco HSBC mas cercano a cubrir tu aportación,
													y poder comenzar tu proceso de reinscripción.
												</p>
											</div>
											<div className="card-footer text-muted">
											{
												usuario.status==='R' ?
													<button className="btn btn-primary" onClick={this.decargarReferencia}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
												:   
													<button className="btn btn-primary" onClick={this.sinDerechoaInscripcion}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
											}
											
											</div>
										</div>
									</div>


				{/* SOLICITUD DE INSCRIPCION */}
					
							
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
												
													{
													//	parseInt(usuario.check3,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
													//	: <span className="oi" data-glyph="document" aria-hidden="true"> </span> 
													}
													Solicitud de reinscripción
												</h5>
											</div>
											<div className="card-body">
												{/*<p className="card-text sm">
													Deberás llenar este formato con tus datos, y después imprimirlo dos veces para que te quedes con una copia
													sellada de recibido. 
												</p>*/}
												<p className="card-text sm">
													Llena este formato con tus datos, descárgalo e imprímelo.
													<br/>Debes entregarlo con tus documentos el dia que te toque inscribirte.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={this.llenarSolicitud}>
													COMPLETAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>
					


				{/* REGLAMENTO ESCOLAR */}
							{
								parseInt(usuario.check3,10)===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													{
														parseInt(usuario.check4,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
														: <span className="oi" data-glyph="file" aria-hidden="true"> </span> 
													}
													Normas De Convivencia
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Descarga y lee las normas de convivencia escolar. 
													Debes imprimir, llenar y entregar la última hoja junto con tus documentos.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={(e)=>this.descargar(e,4)}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>
								:
									""
							}


				{/* CONSENTIMIENTO PARA REVISION DE PERTENENCIAS PERSONALES */}
							{
								parseInt(usuario.check3,10)===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													{
														parseInt(usuario.check10,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
														: <span className="oi" data-glyph="file" aria-hidden="true"> </span> 
													}
													Consentimiento para revisión de pertenencias personales
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Descarga esta Carta Autorizacion para que sea firmada. Esto nos ayudara a prevenir delitos dentro del plantel
													implementando estrategias de seguridad.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={(e)=>this.descargar(e,10)}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>
								:
									""
							}


				{/* AVISO DE PRIVACIDAD */}
{/*							{
								parseInt(usuario.check3,10)===1 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													{
														parseInt(usuario.check11,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
														: <span className="oi" data-glyph="file" aria-hidden="true"> </span> 
													}
													Aviso de Privacidad
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Formato de aviso de privacidad para el uso de datos personales. Debera ser firmado por tu padre o tutor,
													y entregado con tus documentos.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={(e)=>this.descargar(e,11)}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>
								:
									""
							}*/}


				{/* CONSTANCIA */}
							{	
								parseFloat(usuario.promedio,10)>5 ?
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> </span>
													Constancia de estudios
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui puedes descargar tu constancia de estudios o tu constancia de terminación.
												</p>
											</div>
											<div className="card-footer text-muted">
												<div>
												{/*	<button className="btn btn-success mb-2" onClick={this.agregarFotografia}>
														AGREGAR FOTO &nbsp; <span className="oi" data-glyph="data-transfer-upload" aria-hidden="true"> </span>
													</button>*/}
													<br/> 														
													<button className="btn btn-primary" onClick={this.decargarConstancia}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
												</div>
											</div>
										</div>
									</div>
								:
									""
							}


				{/* SOLICITUD DE TIPO A o RECURSAMIENTOS*/}
									
		{/*							<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> </span>
													Solicitud de Examen Extraordinario/Recursamiento
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Entra aqui y revisa si tienes alguna materia reprobada para
													que solicites tu Examen Extraordinario
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={this.solicitarMateriasReprobadas}>
													SOLICITAR &nbsp; <span className="oi" data-glyph="pencil" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>*/}
				

				
				{/* HISTORIAL ACADEMICO*/}
							
									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> </span>
													Historial Académico
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Aqui podras descargar tu hisotiral académico. 
													Si el tuyo no aparece, comunicate con nosotros.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={this.descargarHistorial}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>

				{/* REFERENCIA DE PAGO PARA REPOSICION DE CREDENCIAL */}

{/*									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="spreadsheet" aria-hidden="true"> </span>
													Reposición de credencial
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Si deseas tramitar una reposición de tu credencial escolar, descarga esta hoja y acude con ella
													a un banco HSBC a realizar el pago.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={this.decargarReferenciaCredencial}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>												
											</div>
										</div>
									</div>*/}

				{/* CARTA COMPROMISO */}
				{/*									
							{
								usuario.status==="I" ?

									<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													{
														parseInt(usuario.check6,10)===1 ? <span className="oi verde" data-glyph="task" aria-hidden="true"> </span>
														: <span className="oi" data-glyph="file" aria-hidden="true"> </span> 
													}
													Carta Compromiso
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Como actualmente te encuentras en estado irregular en tus materias, debes descargar esta 
													carta compromiso, llenarla, y enviarla al correo que se indica en ella.
												</p>
											</div>
											<div className="card-footer text-muted">
												<button className="btn btn-primary" onClick={(e)=>this.descargar(e,6)}>
													DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
												</button>
											</div>
										</div>
									</div>
								: ""
							}*/}


							{/* REPORTE TUTORES */}
							
			{/*						<div className="pt-3 pb-3 col-12 col-sm-10 col-md-5 col-lg-4">
										<div className="animated fadeIn card text-center">
											<div className="card-header">
												<h5>
													<span className="oi" data-glyph="document" aria-hidden="true"> </span>
													Reporte Tutores
												</h5>
											</div>
											<div className="card-body">
												<p className="card-text sm">
													Reporte de tutores
												</p>
											</div>
											<div className="card-footer text-muted">
												<div> 														
													<button className="btn btn-primary" onClick={this.decargarReporteTutores}>
														DESCARGAR &nbsp; <span className="oi" data-glyph="data-transfer-download" aria-hidden="true"> </span>
													</button>
												</div>
											</div>
										</div>
									</div>*/}


								</div>
							</div>
						</div>
					</div>

					<div className="row pt-4 pb-4 justify-content-center">					
					</div>
				</div>

				{/* PIE DE PAGINA */}

				<PieDePagina2 />

			</div>

		);
	}

}

export default Descargas;